<template>
  <a-modal
    :confirm-loading="loading"
    title="添加站点"
    :visible="visible"
    @ok="handleOk"
    @cancel="() => { this.$refs.form.resetFields(); this.visible = false }"
  >
    <a-form-model ref="form" :label-col="{ span: 4, style: { textAlign: 'left' } }" :model="form" :rules="rules" :wrapper-col="{ span: 20 }">
      <a-form-model-item label="协议" prop="protocol">
        <a-select v-model="form.protocol">
          <a-select-option value="http">http</a-select-option>
          <a-select-option value="https">https</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="子域名" prop="subdomain">
        <a-input v-model="name" :disabled="true"></a-input>
      </a-form-model-item>
      <a-form-model-item label="URI" prop="uri">
        <a-input v-model="form.uri"></a-input>
      </a-form-model-item>
      <a-form-model-item label="解析 IP" prop="ip_address">
        <a-input v-model="form.ip_address">
          <a-tooltip slot="suffix" title="如果有 CDN，请填写解析 IP 以便获取准确信息">
            <a-icon type="info-circle" style="color: rgba(0,0,0,.45)"></a-icon>
          </a-tooltip>
        </a-input>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { createSubdomainSite } from '@/api/domain'

export default {
  name: 'CreateForm',
  data () {
    return {
      id: '',
      name: '',
      loading: false,
      visible: false,
      form: { protocol: 'http' },
      rules: {
        protocol: [{ message: '请选择协议', required: true }],
        ip_address: [{ message: '请输入正确的 IP 地址', pattern: /^(25[0-5]|2[0-4]\d|[0-1]?\d?\d)(\.(25[0-5]|2[0-4]\d|[0-1]?\d?\d)){3}$/, trigger: 'blur' }]
      }
    }
  },
  methods: {
    show (id, name) {
      this.id = id
      this.name = name
      this.visible = true
    },
    handleOk () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          createSubdomainSite(this.id, this.form).then(res => {
            this.$message.success(res.message)
            this.visible = false
            this.$refs.form.resetFields()
          }).finally(() => { this.loading = false })
        }
      })
    }
  }
}
</script>
