import request from '@/utils/request'

export function getDomains (params) {
  return request.get('/domains', { params })
}

export function createDomain (data) {
  return request.post('/domains', data)
}

export function getDomain (id) {
  return request.get(`/domains/${id}`)
}

export function deleteDomain (id) {
  return request.delete(`/domains/${id}`)
}

export function verifyDomain (id) {
  return request.get(`/domains/${id}/verify`)
}

export function collectDomain (id) {
  return request.get(`/domains/${id}/collect`)
}

export function getSubdomains (id) {
  return request.get(`/domains/${id}/subdomains`)
}

export function createSubdomainSite (id, data) {
  return request.post(`/subdomains/${id}/site`, data)
}
