<template>
  <div>
    <create-form ref="createForm"></create-form>
    <a-table :columns="columns" :data-source="dataSource" :loading="loading" :pagination="false" row-key="id">
      <template slot="operation" slot-scope="text, record">
        <a-button size="small" type="link" @click="$refs.createForm.show(record.id, record.name)">添加</a-button>
      </template>
    </a-table>
  </div>
</template>

<script>
import { getSubdomains } from '@/api/domain'
import CreateForm from './CreateForm'

export default {
  name: 'SubdomainTable',
  components: { CreateForm },
  props: {
    domainId: { type: String, required: true }
  },
  data () {
    return {
      columns: [
        { dataIndex: 'name', title: '子域名', width: 480 },
        { dataIndex: 'updated_at', title: '更新时间', width: 200 },
        { title: '操作', width: 160, fixed: 'right', align: 'center', scopedSlots: { customRender: 'operation' } }
      ],
      dataSource: [],
      loading: false
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      getSubdomains(this.domainId).then(res => { this.dataSource = res.data })
    }
  }
}
</script>
