<template>
  <a-card :bordered="false" title="子域名信息">
    <a-space style="margin-bottom: 24px;">
      <a-button icon="reload" @click="() => { this.fetch(); this.$refs.subdomainTable.fetch() }"></a-button>
      <a-button type="primary" :disabled="disabled" @click="handleClick">{{ detail.state === 1 ? '收集中' : '收集' }}</a-button>
    </a-space>

    <subdomain-table ref="subdomainTable" :domain-id="domainId"></subdomain-table>
    <a-back-top></a-back-top>
  </a-card>
</template>

<script>
import { collectDomain, getDomain } from '@/api/domain'
import SubdomainTable from './modules/SubdomainTable'

export default {
  name: 'DomainDetail',
  components: { SubdomainTable },
  data () {
    return {
      domainId: this.$route.params.id,
      detail: {},
      disabled: false
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      getDomain(this.domainId).then(res => { this.detail = res })
    },
    handleClick () {
      collectDomain(this.domainId).then(res => {
        this.$message.success(res.message)
        this.fetch()
      })
    }
  },
  watch: {
    detail: {
      handler (val) {
        this.disabled = val.state === 1
      },
      deep: true
    }
  }
}
</script>
